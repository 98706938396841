/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';
import { srConfig } from '@config';
import sr from '@utils/sr';

const StyledContactSection = styled.section`
  max-width: 600px;
  margin: 0 auto 100px;
  text-align: center;

  @media (max-width: 768px) {
    margin: 0 auto 50px;
  }

  .overline {
    display: block;
    margin-bottom: 20px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: var(--fz-md);
    font-weight: 400;

    &:before {
      bottom: 0;
      font-size: var(--fz-sm);
    }

    &:after {
      display: none;
    }
  }

  .title {
    font-size: clamp(40px, 5vw, 60px);
  }

  .form {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .input,
    .textarea {
      width: 100%;
      padding: 10px;
      font-size: var(--fz-md);
      border: 1px solid var(--light-slate);
      border-radius: 5px;
      background-color: var(--navy);
      color: var(--white);
      resize: none;

      &:focus {
        outline: none;
        border-color: var(--green);
      }
    }

    .textarea {
      min-height: 100px;
    }

    .submit-button {
      ${({ theme }) => theme.mixins.bigButton};
    }
  }

  .success-message {
    color: var(--green);
    font-size: var(--fz-sm);
    margin-top: 10px;
  }

  .error-message {
    color: var(--red);
    font-size: var(--fz-sm);
    margin-top: 10px;
  }
`;

const Contact = () => {
  const revealContainer = useRef(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [statusType, setStatusType] = useState('');

  useEffect(() => sr.reveal(revealContainer.current, srConfig()), []);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_3d6iufv', // Replace with your EmailJS service ID
        'template_pldgqhg', // Replace with your EmailJS template ID
        e.target,
        'VmQ6CL2KsUdInSlnj' // Replace with your EmailJS user ID
      )
      .then(
        (result) => {
          console.log(result.text);
          setStatusMessage('Message sent successfully!');
          setStatusType('success');
        },
        (error) => {
          console.log(error.text);
          setStatusMessage('Something went wrong. Please try again.');
          setStatusType('error');
        }
      );

    e.target.reset();
  };

  return (
    <StyledContactSection id="contact" ref={revealContainer}>
      <h2 className="numbered-heading overline">What’s Next?</h2>

      <h2 className="title">Get In Touch</h2>

      <p>
        I’m currently looking for new opportunities. Whether you have a question or just want to say
        hi, feel free to send me a message!
      </p>

      <form className="form" onSubmit={handleFormSubmit}>
        <input
          className="input"
          type="text"
          name="user_name"
          placeholder="Your Name"
          required
        />
        <input
          className="input"
          type="email"
          name="user_email"
          placeholder="Your Email"
          required
        />
        <textarea
          className="textarea"
          name="message"
          placeholder="Your Message"
          required
        ></textarea>
        <button className="submit-button" type="submit">
          Send Message
        </button>
      </form>

      {statusMessage && (
        <p className={statusType === 'success' ? 'success-message' : 'error-message'}>
          {statusMessage}
        </p>
      )}
    </StyledContactSection>
  );
};

export default Contact;
