import React from 'react';

const IconLogo = () => (
//  <svg width="84" height="96" viewBox="0 0 84 96" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path fill="currentColor" d="M47.6064 56.6738C47.6064 55.2904 47.1182 54.2324 46.1416 53.5C45.165 52.7513 43.4072 51.9701 40.8682 51.1562C38.3291 50.3262 36.319 49.5124 34.8379 48.7148C30.8014 46.5339 28.7832 43.596 28.7832 39.9014C28.7832 37.9808 29.3203 36.2718 30.3945 34.7744C31.485 33.2607 33.0394 32.0807 35.0576 31.2344C37.0921 30.388 39.3708 29.9648 41.8936 29.9648C44.4326 29.9648 46.695 30.4287 48.6807 31.3564C50.6663 32.2679 52.2044 33.5618 53.2949 35.2383C54.4017 36.9147 54.9551 38.819 54.9551 40.9512H47.6309C47.6309 39.3236 47.1182 38.0622 46.0928 37.167C45.0674 36.2555 43.627 35.7998 41.7715 35.7998C39.9811 35.7998 38.5895 36.1823 37.5967 36.9473C36.6038 37.696 36.1074 38.6888 36.1074 39.9258C36.1074 41.0814 36.6852 42.0498 37.8408 42.8311C39.0127 43.6123 40.7298 44.3447 42.9922 45.0283C47.1589 46.2816 50.1943 47.8359 52.0986 49.6914C54.0029 51.5469 54.9551 53.8581 54.9551 56.625C54.9551 59.7012 53.7913 62.1182 51.4639 63.876C49.1364 65.6175 46.0033 66.4883 42.0645 66.4883C39.3301 66.4883 36.8398 65.9919 34.5938 64.999C32.3477 63.9899 30.6305 62.6146 29.4424 60.873C28.2705 59.1315 27.6846 57.1133 27.6846 54.8184H35.0332C35.0332 58.7409 37.377 60.7021 42.0645 60.7021C43.806 60.7021 45.165 60.3522 46.1416 59.6523C47.1182 58.9362 47.6064 57.9434 47.6064 56.6738Z"/>
// <path d="M42 3L3 25V70L42 93L81 71V26L42 3Z" fill="none" stroke="currentColor"/>
// </svg>

<svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        {/* <path
          d="M45.691667,45.15 C48.591667,46.1 50.691667,48.95 50.691667,52.2 C50.691667,57.95 46.691667,61 40.291667,61 L28.541667,61 L28.541667,30.3 L39.291667,30.3 C45.691667,30.3 49.691667,33.15 49.691667,38.65 C49.691667,41.95 47.941667,44.35 45.691667,45.15 Z M33.591667,43.2 L39.241667,43.2 C42.791667,43.2 44.691667,41.85 44.691667,38.95 C44.691667,36.05 42.791667,34.8 39.241667,34.8 L33.591667,34.8 L33.591667,43.2 Z M33.591667,47.5 L33.591667,56.5 L40.191667,56.5 C43.691667,56.5 45.591667,54.75 45.591667,52 C45.591667,49.2 43.691667,47.5 40.191667,47.5 L33.591667,47.5 Z"
          fill="currentColor"
        /> */}
        <path fill="currentColor" d="M47.6064 56.6738C47.6064 55.2904 47.1182 54.2324 46.1416 53.5C45.165 52.7513 43.4072 51.9701 40.8682 51.1562C38.3291 50.3262 36.319 49.5124 34.8379 48.7148C30.8014 46.5339 28.7832 43.596 28.7832 39.9014C28.7832 37.9808 29.3203 36.2718 30.3945 34.7744C31.485 33.2607 33.0394 32.0807 35.0576 31.2344C37.0921 30.388 39.3708 29.9648 41.8936 29.9648C44.4326 29.9648 46.695 30.4287 48.6807 31.3564C50.6663 32.2679 52.2044 33.5618 53.2949 35.2383C54.4017 36.9147 54.9551 38.819 54.9551 40.9512H47.6309C47.6309 39.3236 47.1182 38.0622 46.0928 37.167C45.0674 36.2555 43.627 35.7998 41.7715 35.7998C39.9811 35.7998 38.5895 36.1823 37.5967 36.9473C36.6038 37.696 36.1074 38.6888 36.1074 39.9258C36.1074 41.0814 36.6852 42.0498 37.8408 42.8311C39.0127 43.6123 40.7298 44.3447 42.9922 45.0283C47.1589 46.2816 50.1943 47.8359 52.0986 49.6914C54.0029 51.5469 54.9551 53.8581 54.9551 56.625C54.9551 59.7012 53.7913 62.1182 51.4639 63.876C49.1364 65.6175 46.0033 66.4883 42.0645 66.4883C39.3301 66.4883 36.8398 65.9919 34.5938 64.999C32.3477 63.9899 30.6305 62.6146 29.4424 60.873C28.2705 59.1315 27.6846 57.1133 27.6846 54.8184H35.0332C35.0332 58.7409 37.377 60.7021 42.0645 60.7021C43.806 60.7021 45.165 60.3522 46.1416 59.6523C47.1182 58.9362 47.6064 57.9434 47.6064 56.6738Z"/>
        {/* <path d="M42 3L3 25V70L42 93L81 71V26L42 3Z" fill="currentColor" stroke="currentColor"/> */}
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
    
  </svg>

);

export default IconLogo;
